import { apiSlice } from "app/api/apiSlice"

export const EMandiApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllFarmersList: builder.query({
            query: (pagination) => `/procurement/api/farmer-stock-detail`
        }),
      
        getAggregationList: builder.query({
            query: ({activeKey}) => `/procurement/api/user-details/?entity_type=${activeKey}`
        }),
        getEmandiReport:builder.query({
            query: ({selectedState,selectedCommodity,selectedMarket,dateFrom,dateTo,pagination}) => `/api/v1/e-mandi/emandi-report?state=${selectedState}&commodity=${selectedCommodity}&market=${selectedMarket}&start_date=${dateFrom}&end_date=${dateTo}&page=${pagination.page}`
        }),
        getAllCommodities: builder.query({
            query: () => `/api/v1/e-mandi/commodities`
        }),
        getAllMarketss: builder.query({
            query: ({selectedState}) => `/api/v1/e-mandi/market-master/?limit=2500&offset=0&state=${selectedState}`
        }),
        getEmandiReportFilters: builder.query({
            query: ({ selectedState, selectedDistrict, crops,start_date,end_date }) =>
              `/api/v1/e-mandi/emandi-report?state=${selectedState}&district=${selectedDistrict}&commodity=${crops}&market=&start_date=${start_date}&end_date=${end_date}=&page=1000`,
          }),
      
      
        
    })
})

export const { useGetAllFarmersListQuery ,useGetAggregationListQuery ,useGetEmandiReportQuery ,useGetAllCommoditiesQuery,useGetAllMarketssQuery,useGetEmandiReportFiltersQuery} = EMandiApiSlice 